import React from 'react';
import { Helmet } from 'react-helmet';

//components
import ContactUs from "../../components/ContactUs/ContactUs";
import GetInTouch from "../../components/GetInTouch/GetInTouch";
import FAQ from "../../components/FAQ/FAQ";

export default function Contact() {


    return (
        <>
            <Helmet>
                <title>Contact</title>
                <meta charset="utf-8" />
                <meta name="description" content="Contact page description" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
            </Helmet>

            <section className="mg-section">
                <ContactUs />
                <GetInTouch />
                <FAQ />
            </section >
        </>
    );
}