import React, { useEffect, useRef, useState } from "react";

// Import images
import About01Img from "../../resources/images/about/about_01.png";
import About02Img from "../../resources/images/about/about_02.png";

export default function About() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    return (
        <div ref={animationRef} className="mg-about container pt-lg-5 pb-lg-5 pt-4 pb-4">
            <h2 className={`mg-about-title fw-bold text-center mb-lg-5 mb-4  animate__animated ${isVisible ? " animate__slideInTop" : " mg-component-hidden"}`}>About Megista</h2>
            <div className="row">
                <div className={`col-12 `}>
                    <div className="megista__about_top">
                        <div className="row mb-4 justify-content-md-between align-items-center">
                            <div className={`col-lg-6 col-md-6 order-md-1 order-2 text-center animate__animated ${isVisible ? " animate__slideInLeft" : " mg-component-hidden"}`}>
                                <img src={About01Img} className="mg-about_img w-100" alt="Megista" />
                            </div>
                            <div className={`col-lg-6 col-md-6 order-md-2 order-1 animate__animated ${isVisible ? " animate__slideInRight" : " mg-component-hidden"}`}>
                                <div className="mg-about-content rounded-4 py-3 px-4">
                                    <h5 className="mg-about-content_title fw-bold">About</h5>
                                    <p className="mg-about-content_info">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the
                                        industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has
                                        survived not only five centuries, but also the leap into
                                        electronic typesetting,
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col-12`}>
                    <div className="megista__about_bottom">
                        <div className="row justify-content-md-between align-items-center">
                            <div className={`col-lg-7 col-md-6 animate__animated ${isVisible ? " animate__slideInLeft" : " mg-component-hidden"}`}>
                                <div className="mg-about-content rounded-4 py-3 px-4">
                                    <h5 className="mg-about-content_title fw-bold">About</h5>
                                    <p className="mg-about-content_info">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the
                                        industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has
                                        survived not only five centuries, but also the leap into
                                        electronic typesetting,
                                    </p>
                                </div>
                            </div>
                            <div className={`col-lg-4 col-md-6 text-center animate__animated ${isVisible ? " animate__slideInRight" : " mg-component-hidden"}`}>
                                <img src={About02Img} className="mg-about_img w-100" alt="Megista" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
