import React, { useEffect, useRef, useState } from "react";
import FaqArrowImg from "../../resources/images/icons/faq_arrow.png";

export default function FAQ() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    const faqData = [
        {
            question: "How can I contact the team for help?",
            answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
            question: "How do I reset my password?",
            answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        },
        {
            question: "Can I change my subscription plan?",
            answer: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
        },
        {
            question: "How do I cancel my subscription?",
            answer: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur.",
        },
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div ref={animationRef} className="mg-faq container pt-lg-5 pb-lg-5 pt-4 pb-4">
            <h2 className={`fw-bold text-center mb-4 mg-faq_title  animate__animated ${isVisible ? " animate__slideInTop" : " mg-component-hidden"}`}>FAQ</h2>
            {faqData.map((faq, index) => (
                <div className={`mg-faq_item  animate__animated ${isVisible ? " animate__slideInUp" : " mg-component-hidden"}`} key={index}>
                    <div className={`mg-faq_item_question p-3 ${activeIndex === index ? 'open' : ''}`} onClick={() => toggleAccordion(index)}>
                        <span className="mg-faq_item_question-title">{faq.question}</span>
                        <img src={FaqArrowImg} alt="Megista" className={`mg-faq_item_question-img ${activeIndex === index ? 'mg-rotate' : ''}`} />
                    </div>
                    <div className={`mg-faq_item_answer ${activeIndex === index ? 'open' : ''}`}>
                        <span className="p-3 pt-0">{faq.answer}</span>
                    </div>
                </div >
            ))
            }
        </div >
    );
}
