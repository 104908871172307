import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

//svg
import MegistaSVG from "../../resources/images/icons/megista.svg";

export default function ContactUs() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    return (
        <div ref={animationRef} className="mg-contact container pt-lg-5 pb-lg-5 pt-4 pb-4">
            <h2 className={`fw-bold text-center mb-lg-5 mb-4 mg-contact_title  animate__animated ${isVisible ? " animate__slideInTop" : " mg-component-hidden"}`}>CONTACT US</h2>
            <div className="row px-3">
                <div className="col-md-4 mg-contact-border mb-4 mb-md-0 pb-4 pb-md-0">
                    <div className={`mg-contact-box  animate__animated ${isVisible ? " animate__slideInLeft" : " mg-component-hidden"}`}>
                        <div className="mg-contact-box_icon text-center">
                            <svg className="mg-contact-box_icon_svg">
                                <use xlinkHref={`${MegistaSVG}#mg_phone`} />
                            </svg>
                        </div>
                        <span className="mg-contact-box_title text-center">Phone</span>
                        <div className="mg-contact-box_body text-center">
                            <Link to="+374 111 111" className="mb-1">+374 111 111</Link>
                            <Link to="+374 111 111">+374 111 111</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mg-contact-border mb-4 mb-md-0 pb-4 pb-md-0">
                    <div className={`mg-contact-box  animate__animated ${isVisible ? " animate__slideInUp" : " mg-component-hidden"}`}>
                        <div className="mg-contact-box_icon text-center">
                            <svg className="mg-contact-box_icon_svg">
                                <use xlinkHref={`${MegistaSVG}#mg_carbon_location`} />
                            </svg>
                        </div>
                        <span className="mg-contact-box_title text-center">Address</span>
                        <div className="mg-contact-box_body text-center">
                            <Link to="#" className="mb-1">Hakob Hakobyan Street, Yerevan</Link>
                            <Link to="#">Hakob Hakobyan Street, Yerevan</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={`mg-contac-box  animate__animated ${isVisible ? " animate__slideInRight" : " mg-component-hidden"}`}>
                        <div className="mg-contact-box_icon text-center">
                            <svg className="mg-contact-box_icon_svg">
                                <use xlinkHref={`${MegistaSVG}#mg_email`} />
                            </svg>
                        </div>
                        <span className="mg-contact-box_title text-center">Email</span>
                        <div className="mg-contact-box_body text-center">
                            <Link href="#" className="mb-1">argine@megista.io</Link>
                            <Link href="#">argine@megista.io</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
