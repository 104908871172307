import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Offcanvas } from 'react-bootstrap';

// Import images
import LogoImg from "../../resources/images/logo.png";

//svg
import MegistaSVG from "../../resources/images/icons/megista.svg";

export default function Header() {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        handleClose();
    }, [location]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navbarClass = scrolled ? 'mg-header_cover-navbar mg-header_cover-navbar-bg_blue' : 'mg-header_cover-navbar';

    return (
        <header className="mg-header">
            <div className="container-fluid mg-header_cover p-0">
                <div className={navbarClass + " container-fluid"}>
                    <nav className="navbar">
                        <div className="container">
                            <Link to="/home" className="mg-header_cover-navbar_logo navbar-brand">
                                <img src={LogoImg} alt="Page Logo" />
                            </Link>
                            <div className="mg-header_cover-navbar_nvs ml-auto">
                                <ul className="mg-header_cover-navbar_items-nav flex-row justify-content-center mb-0 px-0">
                                    <li className="mg-header_cover-navbar_items_item">
                                        <Link to="/home" className="nav-link mg-navbar-btn active__nav">Home</Link>
                                    </li>
                                    <li className="mg-header_cover-navbar_items_item">
                                        <Link to="/about" className="nav-link mg-navbar-btn">About us</Link>
                                    </li>
                                    <li className="mg-header_cover-navbar_items_item">
                                        <Link to="/contact" className="nav-link mg-navbar-btn">Contact us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="mg-header_cover-navbar_burger d-block d-md-none" onClick={handleShow}>
                                <span className="mg-header_cover-navbar_burger_item"></span>
                                <span className="mg-header_cover-navbar_burger_item"></span>
                                <span className="mg-header_cover-navbar_burger_item"></span>
                            </div>

                            <Offcanvas show={show} onHide={handleClose} placement="end" className="text-light mg-header_cover-navbar-custom_offcanvas">
                                <Offcanvas.Header className="mg-custom_menu_close ">
                                    <div className="mg-custom_menu_close_btn container">
                                        <svg className="mg-custom_menu_close_btn_icon" onClick={handleClose}>
                                            <use xlinkHref={`${MegistaSVG}#mg-close_icon`} />
                                        </svg>
                                    </div>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div className="mg-header_cover-navbar_items ml-auto">
                                        <ul className="mg-header_cover-navbar_items-nav flex-row justify-content-center">
                                            <li className="mg-header_cover-navbar_items_item mb-3">
                                                <Link to="/home" className="nav-link mg-navbar-btn active__nav">Home</Link>
                                            </li>
                                            <li className="mg-header_cover-navbar_items_item mb-3">
                                                <Link to="/about" className="nav-link mg-navbar-btn">About us</Link>
                                            </li>
                                            <li className="mg-header_cover-navbar_items_item">
                                                <Link to="/contact" className="nav-link mg-navbar-btn">Contact us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                    </nav>
                </div>
                <div className="mg-header_cover-title mt-5">
                    <div className="container">
                        <h1 className="mg-header_cover-title_tlt">Lorem Ipsum is simply <br /> dummy text</h1>
                        <span className="mg-header_cover-title_inf">Lorem Ipsum is simply dummy text of the printing</span>
                    </div>
                </div>
                <div className="mg-header_cover-social">
                    <div className="container">
                        <div className="mg-header_cover-social_links">
                            <Link to="#" className="btn border-0 svg-container_discort">
                                <svg className="mg-header_cover-social_links_icon_discord">
                                    <use xlinkHref={`${MegistaSVG}#ic_baseline-discord`} />
                                </svg>
                            </Link>
                            <Link to="#" className="btn border-0">
                                <svg className="mg-header_cover-social_links_icon_facebook">
                                    <use xlinkHref={`${MegistaSVG}#ic_baseline-facebook`} />
                                </svg>
                            </Link>
                            <Link to="#" className="btn border-0">
                                <svg className="mg-header_cover-social_links_icon_youtube">
                                    <use xlinkHref={`${MegistaSVG}#ant-design_youtube-filled`} />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    );
}
