import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// Import images
import LogoWhiteImg from "../../resources/images/mg_logo_white.png";

export default function Company() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    return (
        <div ref={animationRef}
            className={`mg-company container pt-lg-5 pt-4`}>
            <h2 className={`fw-bold text-center mb-lg-5 mb-4 mg-company_title  animate__animated ${isVisible ? " animate__slideInTop" : " mg-component-hidden"}`}>Company</h2>
            <div className="mg-company_box pb-lg-5 pb-4">
                <div className={`mg-company_box_emblem mb-xl-0 col-xl-5 col-lg-6 animate__animated ${isVisible ? " animate__slideInLeft" : " mg-component-hidden"}`}>
                    <img src={LogoWhiteImg} alt="Megista" />
                </div>
                <div className={`col-xl-7 col-lg-6 mg-company_box_info animate__animated ${isVisible ? " animate__slideInRight" : " mg-component-hidden"}`}>
                    <div className="col-12 mb-3 mg-company_box-about">
                        <h5 className="mg-company_box-about_title mb-3">About</h5>
                        <span className="mb-0 mg-company_box-about_info">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unk...
                        </span>
                    </div>
                    <div className="col-12 mg-company_box-img2 mb-3">
                        <img src={LogoWhiteImg} alt="Megista" />
                    </div>
                    <div className="col-12 mg-company_box-about">
                        <h5 className="mg-company_box-about_title">About</h5>
                        <span className="mb-0 mg-company_box-about_info">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printe...
                        </span>
                    </div>
                    <div className="col-12 mg-company_readme pt-3">
                        <Link to="#" className="btn border-0 mg-read-me-btn">{"Read me >>"}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
