import React, { useEffect, useRef, useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import images
import Members04Img from "../../resources/images/members/members_04.png";
import Members05Img from "../../resources/images/members/members_05.png";
import Members06Img from "../../resources/images/members/members_06.png";
import Members07Img from "../../resources/images/members/members_07.png";

export default function Members() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    const slides = [
        { title: 'Name Surname', text: 'Ui/Ux Designer', image: Members04Img },
        { title: 'Name Surname', text: 'Ui/Ux Designer', image: Members05Img },
        { title: 'Name Surname', text: 'Ui/Ux Designer', image: Members06Img },
        { title: 'Name Surname', text: 'Ui/Ux Designer', image: Members07Img }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div ref={animationRef} className="mg-members container pt-lg-5 pb-lg-5 pt-4 pb-4">
            <h2 className={`fw-bold text-center mb-lg-5 mb-4 mg-members_title  animate__animated ${isVisible ? " animate__slideInTop" : " mg-component-hidden"}`}>Members</h2>
            <div className="row justify-content-center">
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <div key={index} className={`mg-members-card   animate__animated ${isVisible ? " animate__slideInUp" : " mg-component-hidden"}`}>
                            <img src={slide.image} className="mg-members-card_img mb-4" alt="Megista" />
                            <h5 className="mg-members-card_content_title fw-bold">{slide.title}</h5>
                            <span className="mg-members-card_content_subtitle">{slide.text}</span>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}
