import React, { useEffect, useRef, useState } from "react";

export default function Statistics() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    return (
        <div ref={animationRef}>
            <div className="pt-lg-5 pt-4 container mg-statistics-border"></div>
            <h2 className={`fw-bold text-center mb-4 mg-statistics-title  animate__animated ${isVisible ? " animate__slideInTop" : " mg-component-hidden"}`}>Statistics</h2>
            <div className={`mg-statistics container pt-4 pb-2 pb-md-4  animate__animated ${isVisible ? " animate__slideInUp" : " mg-component-hidden"}`}>
                <div className={`mg-statistics_content rounded-4 px-3`}>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 text-center mg-statistics_content-item">
                            <p className="mg-statistics_content-number">873</p>
                            <span className="mg-statistics_content-text">Twitch Streams</span>
                        </div>
                        <div className="col-lg-3 col-sm-6 text-center mg-statistics_content-item">
                            <p className="mg-statistics_content-number">873</p>
                            <span className="mg-statistics_content-text">Twitch Streams</span>
                        </div>
                        <div className="col-lg-3 col-sm-6 text-center mg-statistics_content-item">
                            <p className="mg-statistics_content-number">873</p>
                            <span className="mg-statistics_content-text">Twitch Streams</span>
                        </div>
                        <div className="col-lg-3 col-sm-6 text-center mg-statistics_content-item">
                            <p className="mg-statistics_content-number">873</p>
                            <span className="mg-statistics_content-text">Twitch Streams</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-lg-5 pb-4 container"></div>
        </div>
    );
}
