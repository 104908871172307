import React from 'react';
import { Helmet } from 'react-helmet';

//components
import AboutMegista from "../../components/About/About";
import Statistics from "../../components/Statistics/Statistics";
import OurVision from "../../components/OurVision/OurVision";
import Subscribe from "../../components/Subscribe/Subscribe";
import Members from "../../components/Members/Members";

export default function About() {

    return (
        <>
            <Helmet>
                <title>About</title>
                <meta charset="utf-8" />
                <meta name="description" content="About page description" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
            </Helmet>

            <section className="mg-section">
                <AboutMegista />
                <Statistics />
                <OurVision />
                <Subscribe />
                <Members />
            </section >
        </>
    );
}