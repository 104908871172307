import React, { useEffect, useRef, useState } from "react";

// Import images
import Vision01Img from "../../resources/images/vision/vision_01.png";
import Vision02Img from "../../resources/images/vision/vision_02.png";
import Vision03Img from "../../resources/images/vision/vision_03.png";

export default function OurVision() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    return (
        <div ref={animationRef} className="mg-vision container pt-lg-5 pb-lg-5 pt-4 pb-4">
            <h2 className={`fw-bold text-center mb-lg-5 mb-4 mg-vision_title  animate__animated ${isVisible ? " animate__slideInTop" : " mg-component-hidden"}`}>Our Vision</h2>
            <div className="row justify-content-between">
                <div className={`col-lg-4 mb-5 mb-md-0  animate__animated ${isVisible ? " animate__slideInUp" : " mg-component-hidden"}`}>
                    <div className="mg-vision-card text-center mx-md-5">
                        <img src={Vision01Img} className="mg-vision-card_img" alt="Megista" />
                        <h2 className="mg-vision-card_title my-1">Creative</h2>
                        <span className="mg-vision-card_info">
                            InnovationAt Megista, we love to create new and exciting
                            games. Our team is always thinking of fresh ideas and ways
                            to make gaming even more fun. We believe in being bold and
                            trying new things, to give our players the best experience
                            possible.
                        </span>
                    </div>
                </div>
                <div className={`col-lg-4 mb-5 mb-md-0  animate__animated ${isVisible ? " animate__slideInUp" : " mg-component-hidden"}`}>
                    <div className="mg-vision-card text-center mx-md-5">
                        <img src={Vision02Img} className="mg-vision-card_img" alt="Megista" />
                        <h2 className="mg-vision-card_title my-1">Creative</h2>
                        <span className="mg-vision-card_info">
                            Commitment to Quality"Quality is our top priority at
                            Megista. We check every detail to make sure our games are
                            the best they can be. Our players deserve the best, and
                            we’re dedicated to making that happen.
                        </span>
                    </div>
                </div>
                <div className={`col-lg-4  animate__animated ${isVisible ? " animate__slideInUp" : " mg-component-hidden"}`}>
                    <div className="vision__card text-center mx-md-5">
                        <img src={Vision03Img} className="mg-vision-card_img" alt="Megista" />
                        <h2 className="mg-vision-card_title my-1">Creative</h2>
                        <span className="mg-vision-card_info">
                            Player SatisfactionAt Megista, our players are at the heart
                            of everything we do. We listen, learn, and take action based
                            on their feedback to constantly improve our games. Our goal
                            is simple: to bring joy and fun to everyone who plays our
                            games.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
