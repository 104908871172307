import React from 'react';
import { Helmet } from 'react-helmet';

//components
import Company from "../../components/Company/Company";
import Subscribe from "../../components/Subscribe/Subscribe";
import Game from "../../components/Game/Game";
import Statistics from "../../components/Statistics/Statistics";

export default function Home({ alt }) {

    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta charset="utf-8" />
                <meta name="description" content="Home page description" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
            </Helmet>

            <section className="mg-section">
                <Company />
                <Subscribe />
                <Game />
                <Statistics />
            </section >
        </>
    );
}