import React, { useEffect } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';

//pages
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";

//components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import WOW from 'wowjs';
import 'animate.css/animate.min.css';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
  }, []);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          } />
        <Route
          path="home"
          element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          } />
        <Route
          path="contact"
          element={
            <>
              <Header />
              <Contact />
              <Footer />
            </>
          } />
        <Route
          path="about"
          element={
            <>
              <Header />
              <About />
              <Footer />
            </>
          } />
      </Routes>
    </>
  );
}

export default App;
