import React, { useEffect, useRef, useState } from "react";

// Import images
import SendImg from "../../resources/images/icons/send.png";

export default function Subscribe() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    return (
        <div ref={animationRef}>
            <div className="pt-lg-5 pt-4 container mg-subscribe-border"></div>
            <h2 className={`fw-bold text-center mb-4 mg-subscribe-title animate__animated ${isVisible ? "animate__slideInTop" : " mg-component-hidden"}`}>Subscribe to Megista</h2>
            <div className={`mg-subscribe container pt-lg-5 pb-lg-5 pt-4 pb-4 animate__animated ${isVisible ? "animate__fadeInUp" : " mg-component-hidden"}`}>
                <div className="mg-subscribe-box input-group justify-content-center flex-md-row px-4">
                    <input
                        type="text"
                        className="mg-subscribe-box_input px-2 py-2"
                        placeholder="Placeholder/Input text"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                    />
                    <button
                        className="btn mg-subscribe-box_btn border-0"
                        type="button"
                        id="button-addon2"
                    >
                        <img src={SendImg} alt="Megista" />
                    </button>
                </div>
            </div>
            <div className="pb-lg-5 pb-4 container"></div>
        </div>
    );
}
