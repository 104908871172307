import React from "react";
import { Link } from "react-router-dom";

// Import images
import LogoImg from "../../resources/images/logo.png";
import FooterHeartImg from "../../resources/images/footer/footer_heart.png";

export default function Footer() {
  return (
    <footer className="mg-footer container-fluid p-0">
      <div className="container mg-footer-container pt-lg-5 pb-lg-5 pt-4 pb-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="mg-footer-container-title p-3 mg-footer-container-border pt-0">
              <div className="mg-footer-container-title_logo">
                <img src={LogoImg} alt="Page Logo" />
              </div>
              <span className="mg-footer-container-title_text">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has.
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 p-lg-0 ps-3">
            <div className="mg-footer-container-content p-3 pt-0 d-flex flex-column align-items-md-left align-items-lg-center h-100 mg-footer-container-border">
              <span className="mg-footer-container-content_title">Content</span>
              <div className="content d-flex flex-column">
                <Link to="/home" className="mg-footer-container-content_link mb-3">Home</Link>
                <Link to="/about" className="mg-footer-container-content_link mb-3">About us</Link>
                <Link to="/contact" className="mg-footer-container-content_link">Contact us</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 p-lg-0 ps-3">
            <div className="d-flex flex-column align-items-md-center h-100">
              <div className="mg-footer-container-contact p-3 pt-0">
                <span className="mg-footer-container-contact_title">Contact</span>
                <div className="mb-3">
                  <span className="mg-footer-container-contact_subtitle mb-2">Location</span>
                  <Link to="#" className="mg-footer-container-contact_text">Hakob Hakobyan Street, Yerevan</Link>
                </div>
                <div>
                  <span className="mg-footer-container-contact_subtitle mb-2">Phone</span>
                  <Link to="#" className="mg-footer-container-contact_text">+374 99 99 99 99</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mg-footer-privecy container-fluid pb-3 pt-3">
        <div className="container">
          <div className="row py-2 justify-content-between">
            <div className="col-md-6 mg-footer-privecy_left text-center text-md-start mb-2 mb-md-0">
              <p className="mg-footer-privecy_text mb-0">© 2023 MEGISTA WITH <span>
                <img src={FooterHeartImg} alt="Megista" />
              </span> BY ARGINE</p>
            </div>
            <div className="col-md-6 text-left text-md-end">
              <span className="mg-footer-privecy_text mb-0">TERMS & CONDITION <span>||</span> PRIVACY POLICY</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
