import React, { useEffect, useRef, useState } from "react";

// Import images
import SendImg from "../../resources/images/icons/send.png";

export default function GetInTouch() {
    const [isVisible, setIsVisible] = useState(false);
    const animationRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting)
                    setIsVisible(true);
            },
            {
                threshold: 0.5
            }
        );

        const currentRef = animationRef.current;

        if (currentRef)
            observer.observe(currentRef);
        return () => {
            if (currentRef)
                observer.unobserve(currentRef);
        };
    }, []);

    return (
        <div ref={animationRef} className="mg-get-in-touch container pt-lg-5 pb-lg-5 pt-4 pb-4">
            <div className="row">
                <div className="col-md-10 mx-auto">
                    <div className="mg-get-in-touch_content rounded-4 px-4 pb-lg-5 pt-lg-5 pb-4 pt-4">
                        <div className="row justify-content-center">
                            <div className={`col-lg-8 col-md-6 mb-lg-5 mb-4  animate__animated ${isVisible ? " animate__slideInTop" : " mg-component-hidden"}`}>
                                <div className="mg-get-in-touch_content_title text-center">
                                    <h2 className="fw-bold">GET IN TOUCH</h2>
                                    <span>
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="row">
                                    <div className={`col-md-6 mb-4 mb-md-0  animate__animated ${isVisible ? " animate__slideInLeft" : " mg-component-hidden"}`}>
                                        <input
                                            type="text"
                                            className="mg-get-in-touch_content_input input__name w-100 p-3"
                                            placeholder="Name"
                                        />
                                    </div>
                                    <div className={`col-md-6  animate__animated ${isVisible ? " animate__slideInRight" : " mg-component-hidden"}`}>
                                        <input
                                            type="text"
                                            className="mg-get-in-touch_content_input input__email w-100 p-3"
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className={`mg-get-in-touch_content_text  animate__animated ${isVisible ? " animate__slideInUp" : " mg-component-hidden"}`}>
                                    <textarea
                                        className="mg-get-in-touch_content_text_input p-3"
                                        placeholder="Text..."
                                    ></textarea>
                                    <button
                                        className="btn mg-get-in-touch_content_text_btn border-0"
                                        type="button"
                                        id="button-addon2"
                                    >
                                        <img src={SendImg} alt="Megista" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
